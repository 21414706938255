* {
  box-sizing: border-box;
}

.App {
  font-family: 'Roboto', sans-serif;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: #333333;
  background-color: #ffffff;
}
